@mixin button {
  margin: 0;
  padding: 0;
  border-radius: 100%;
  border: none;
  background: var(--black);
  color: var(--white);

  width: var(--size);
  height: var(--size);

  font-size: 2vmax;
  font-weight: bold;

  text-transform: uppercase;

  transition: opacity 250ms;

  &:disabled {
    opacity: 0.5;
  }
}

.controls {
  --size-lg: 24vw;
  --size-md: 16vw;
  --size-sm: 12vw;

  display: flex;
  position: absolute;
  width: 100%;
  bottom: env(safe-area-inset-bottom);
  padding-bottom: calc(var(--size-lg) / 4);

  justify-content: space-evenly;
  align-items: center;
}

.primary-button {
  --size: var(--size-lg);
  @include button;
}

.secondary-button,
.tertiary-button {
  --size: var(--size-md);
  @include button;
}

.left-button,
.right-button {
  --size: var(--size-sm);
  @include button;

  color: var(--black);
  background: transparent;
  border: 1px solid var(--black);
}
