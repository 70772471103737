.info {
  position: absolute;
  --padding: calc(2vw);

  display: flex;
  flex-direction: column;
  gap: 1vw;

  width: 50vw;

  padding: var(--padding);
  margin: var(--margin);
  box-sizing: border-box;

  background: var(--white);
  opacity: 0.5;
}

.task {
  position: absolute;
  --size: 25vmin;
  margin: 1em;
  width: var(--size);
  height: var(--size);
  border-radius: 100%;

  font-size: 0.8em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: hsla(0, 0%, 20%, 1);
}
