.panels {
  position: absolute;
  width: 100%;
  height: 100%;

  pointer-events: none;

  transform: translate(50%, 50%);
}

.panel {
  position: absolute;

  font-size: 0.8em;
  line-height: 0.8em;

  // prettier-ignore
  transform: translate3d(
    calc((-1 * (var(--x) + var(--r)) + var(--cx)) * -1px * var(--scale)),
    calc((-1 * (var(--y) - var(--r)) + var(--cy)) * -1px * var(--scale) - var(--vy) * .1px),
    0
  );

  padding: 0.5em;
  margin-left: 0.5em;
  border: 1px solid var(--black);
  background: hsla(0, 0%, 50%, 0.5);
  border-radius: 2px;

  &--patch {
    padding: 0;
    border: none;
    background: none;
    opacity: 0.5;
  }

  // TODO refactor
  padding: 0;
  border: none;
  background: none;
  opacity: 0.5;
}
