.settings {
  position: fixed;
  padding: 1em;
  box-sizing: border-box;

  display: flex;
  flex-direction: column-reverse;

  background: var(--white);
  right: 0;
  top: 0;
  bottom: 0;
  width: 90vw;
}
